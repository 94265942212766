import axiosClient from '@/axios'

const routeAuthenticated = '/auth'

const me = async () => {
   return await axiosClient.get(`${routeAuthenticated}/me`);
}

const profileEdit = async me => {
   return await axiosClient.post(`${routeAuthenticated}/profile/edit`, me);
}

export default {
   me,
   profileEdit
}
