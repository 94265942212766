<template>
   <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
         <div class="container-fluid">
            <div class="row mb-2">
               <div class="col-sm-6">
                  <h1 class="m-0">{{ $t('profile') }}</h1>
               </div><!-- /.col -->
               <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                     <li class="breadcrumb-item"> {{ $t('profile') }} </li>
                  </ol>
               </div><!-- /.col -->
            </div><!-- /.row -->
         </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
         <div v-if="isLoading">
            <p class="text-center"> {{ $t('loading') }} </p>
         </div>
         <div v-else class="row">
            <div class="col-md-12">
               <div class="card card-outline card-info">
                  <div class="card-header">
                     <h3 class="card-title">
                        {{ $t('update_profile') }}
                     </h3>
                  </div>
                  <!-- /.card-header -->

                  <div class="card-body">
                     <div class="row">
                        <div class="col-6">
                           <div class="form-group mb-4">
                              <label for="exampleInputEmail1">{{ $t('name') }}</label>
                              <input type="text" v-model="name" class="form-control" :placeholder="$t('name')">
                           </div>
                        </div>
                        <div class="col-6">
                           <div class="form-group mb-4">
                              <label for="exampleInputEmail1">{{ $t('email') }}</label>
                              <input type="text" v-model="email" class="form-control" :placeholder="$t('email')">
                           </div>
                        </div>

                        <div class="col-6">
                           <div class="form-group mb-4">
                              <label for="exampleInputEmail1">{{ $t('change_password') }}</label>
                              <input type="text" v-model="password" class="form-control" :placeholder="$t('password')">
                           </div>
                        </div>

                     </div>
                     <!-- ./row -->

                  </div>
                  <div class="card-footer">
                     <div class="row justify-content-end">
                        <button class="btn btn-success btn-sm text-bold" @click.prevent="save">{{ $t('save') }}</button>
                     </div>
                  </div>
               </div>
            </div>
            <!-- /.col-->
         </div>
         <!-- ./row -->
      </section>
   </div>
</template>

<script>
import profileService from "../services/profile-service";
import { useToast } from 'vue-toastification'

export default {
   name: "Profile",
   setup() {
      const toast = useToast()

      return { toast }
   },
   data () {
      return {
         name: '',
         email: '',
         password: '',
         isLoading: false
      }
   },
   async created() {
      this.isLoading = true;

      try {
         const { data: response } = await profileService.me();
         this.name = response.user.name;
         this.email = response.user.email;
      } catch (e) {}
      finally {
         this.isLoading = false;
      }
   },
   methods: {
      async save () {
         const me = {
            name: this.name,
            email: this.email
         }

         if (this.password !== '')
            me.password = this.password;

         try {
            await profileService.profileEdit(me);
            this.toast.success(this.$t("updated"));
         } catch (e) {
            this.toast.error(this.$t("update_failure"));
         }
      }
   }
}
</script>



<style scoped>

</style>
